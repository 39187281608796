export const orugaConfig = {
  iconPack: "",
  iconComponent: "material-icon",
  statusIcon: true,
  button: {
    rootClass: "btn",
    variantClass: "btn-",
    roundedClass: "btn-rounded",
    outlinedClass: "btn-outlined-",
    disabledClass: "btn-disabled",
    sizeClass: "btn-size-",
  },
  field: {
    rootClass: "field",
    labelClass: "field-label",
    messageClass: "text-sm italic",
    variantClass: "field-",
    variantMessageClass: "field-message-",
  },
  input: {
    inputClass: "input",
    roundedClass: "rounded",
    variantClass: "input-",
    iconRightClass: "input-icon-right",
  },
  inputitems: {
    itemClass: "inputitems-item",
    containerClass: "rounded",
  },
  autocomplete: {
    menuClass: "autocomplete-menu",
    itemClass: "autocomplete-item",
  },
  icon: {
    variantClass: "icon-",
  },
  checkbox: {
    checkClass: "checkbox",
    checkCheckedClass: "checkbox-checked",
    labelClass: "checkbox-label",
  },
  dropdown: {
    rootClass: "dropdown",
    menuClass: "dropdown-menu",
    itemClass: "dropdown-item",
    itemActiveClass: "dropdown-item-active",
  },
  steps: {
    itemHeaderActiveClass: "steps-nav-item-active",
    itemHeaderPreviousClass: "steps-nav-item-previous",
    stepMarkerClass: "step-marker",
    stepDividerClass: "step-divider",
  },
  datepicker: {
    iconNext: "ChevronRight",
    iconPrev: "ChevronLeft",
  },
  modal: {
    rootClass: "modal",
    contentClass: "modal-content",
  },
  switch: {
    labelClass: "switch-label",
    checkCheckedClass: "switch-check-checked",
  },
  select: {
    selectClass: "select",
  },
  radio: {
    checkCheckedClass: "radio-checked",
    checkClass: "form-radio",
    labelClass: "radio-label",
  },
  notification: {
    rootClass: "notification",
    variantClass: "notification-",
  },
  table: {
    tableClass: "table",
    tdClass: "table-td",
    thClass: "table-th",
    rootClass: "table-root",
  },
  pagination: {
    rootClass: "pagination",
    simpleClass: "pagination-simple",
    listClass: "pagination-list",
    infoClass: "pagination-info",
    linkClass: "pagination-link",
    linkCurrentClass: "pagination-link-current",
    linkDisabledClass: "pagination-link-disabled",
    nextBtnClass: "pagination-next",
    prevBtnClass: "pagination-previous",
    ellipsisClass: "pagination-ellipsis",
  },
  tabs: {
    rootClass: "tabs",
    navTabsClass: "tabs-nav",
    navTypeClass: "tabs-nav-",
    navSizeClass: "tabs-nav-",
    tabItemWrapperClass: "tabs-nav-item-wrapper",
    itemHeaderTypeClass: "tabs-nav-item-",
    itemHeaderActiveClass: "tabs-nav-item-active-",
  },
  tooltip: {
    rootClass: "tooltip",
    contentClass: "tooltip-content",
    arrowClass: "tooltip-arrow",
    variantClass: "tooltip-content-",
  },
};
